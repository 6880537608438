@import '@vidstack/react/player/styles/default/theme.css';
@import '@vidstack/react/player/styles/default/layouts/audio.css';
@import '@vidstack/react/player/styles/default/layouts/video.css';

.player {
  --brand-color: #e8e8e8; /* Light text color */
  --focus-color: #4e9cf6; /* Accent color */

  --audio-brand: #e8e8e8;
  --audio-focus-ring-color: #4e9cf6;
  --audio-border-radius: 0.3rem;

  --video-brand: #e8e8e8;
  --video-focus-ring-color: #4e9cf6;
  --video-border-radius: 0.3rem;
  --video-border: none;

  /* 👉 https://vidstack.io/docs/player/components/layouts/default#css-variables for more. */
}

@keyframes popInAnimation {
  0% {
    opacity: 0;
    transform: scale(0.99);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.player[data-view-type='audio'] .vds-poster {
  display: none;
  border-radius: 5rem;
}

.player[data-view-type='video'] {
  aspect-ratio: 16 / 9;
}

.src-buttons {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 40px;
  margin-inline: auto;
  max-width: 300px;
}

.vds-poster {
  object-fit: cover;
  border-radius: 0.3rem;
  scale: 2.8;
  margin-top: 20%;
}
